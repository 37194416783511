<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit()" *ngIf="isLoaded" enctype="multipart/form-data">
  <nb-card>
    <nb-card-body>


      <div class="row">
        <div class="col-12 col-md-8">
          <nb-card-body>
            <div class="mb-3" style="margin-top: 20px;">
              <div class="form-floating">
                <input type="text" class="form-control" id="title"
                       formControlName="title" placeholder="Title">
                <label for="title">Title*</label>
              </div>
            </div>


            <div class="mb-3" style="margin-top: 20px;">
              <div class="form-floating">
                <input type="text" class="form-control" id="link_to"
                       formControlName="link_to" placeholder="link_to">
                <label for="title">Link*</label>
              </div>
            </div>
          </nb-card-body>

          <div>
            <mat-form-field appearance="fill">
              <mat-label>Visible From</mat-label>
              <input matInput [matDatepicker]="pickerFrom" formControlName="start" name="start"
                     (dateChange)="onDateChange($event.value, 'start')">
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Visible To</mat-label>
              <input matInput [matDatepicker]="pickerTo" formControlName="end" name="end"
                     (dateChange)="onDateChange($event.value, 'end')">
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>

          <nb-card>
            <nb-card-footer style="text-align:right;">
              <div class="row">
                <div class="col-6" *ngIf="pageFormGroup.get('id')?.value">
                  <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()" *ngIf="pageId">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                    <span>Delete</span>
                  </button>
                </div>

                <div class="col-6" *ngIf="!pageFormGroup.get('id')?.value">
                </div>


                <div class="col-12 col-md-6" style="text-align:right;">

                  <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                    <nb-icon icon="save-outline"></nb-icon>
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </nb-card-footer>
          </nb-card>
        </div>


        <div class="col-12 col-md-4">


          <nb-card>
            <nb-card-header>
              Images
            </nb-card-header>

            <nb-card-body>
              <div class="row mb-3">
                <div class="col-12 col-md-12">
                  <nb-card class="mb-3">
                    <nb-card-header>
                      Main image
                    </nb-card-header>
                    <nb-card-body class="p-0">
                      <div class="upload-container">
                        <input type="file" class="fileInput" id="fileInput" (change)="handleFileInput($event, 'image')">
                        <label for="fileInput" class="upload-label">
                          <img [src]="pageFormGroup?.get('image')?.value || image_url || '/assets/images/image.png'"
                               alt="Upload Image">
                        </label>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </div>

              </div>


            </nb-card-body>
          </nb-card>


        </div>

      </div>


    </nb-card-body>
  </nb-card>
</form>
