import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import { PodcastsService } from '../../services/podcasts.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { GetConfigurationService } from '../../../../services/getConfiguration/get-configuration.service';
import { Share } from '../../../../class/share';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { PodcastFormComponent } from '../../components/podcast-form/podcast-form.component';

@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.scss']
})
export class PodcastsComponent extends Share implements OnInit {
  paginationData: any;
  podcast: any;
  locales: any;
  currentPage: number = 1;
  totalPages: number = 1;

  isLoading: boolean = true;
  @Input() page: any;


  constructor(
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    deviceService: DeviceDetectorService,
    private podcastsService: PodcastsService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    super('Child', deviceService);
    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.locales = datas?.data?.locales;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.loadPagination(this.currentPage);

  }

  loadPagination(page: number): void {
    this.podcastsService.pagination(this.page?.id, page).subscribe(
      (data) => {
        this.paginationData = data?.podcasts.data;
        this.currentPage = data?.podcasts.current_page;
        this.totalPages = data?.podcasts.last_page;
        console.log('Pagination Data:', this.paginationData);
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching pagination data:', error);
      }
    );
  }

  changePage(page: number): void {
    this.loadPagination(page);
  }

  addPodcast(pageId: any) {


    pageId = this.page.id;

    let width = '70vw';
    if (this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: PodcastFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: 'Add new podcast',
      nzContentParams: {
        locales: this.locales,
        pageId: pageId,
        page: this.page,
      }
    });

    modalRef.afterClose.subscribe(() => {
      this.loadPagination(this.currentPage);
    });
  }

  editPodcast(id: any) {
    let pageId = id;

    let width = '70vw';
    if (this.isMobile) {
      width = '100vw';
    }

    let modalRef = this._NzDrawerService.create({
      nzContent: PodcastFormComponent,
      nzWidth: width,
      nzWrapClassName: 'nopadding',
      nzTitle: 'Add new podcast',
      nzContentParams: {
        locales: this.locales,
        pageId: pageId,
        id: id,
      }
    });

    modalRef.afterClose.subscribe(() => {
      this.loadPagination(this.currentPage);
    });
  }
}
