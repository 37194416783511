


<full-calendar *ngIf='calendarVisible()' [options]='calendarOptions'>
  <ng-template #eventContent let-arg  >

    <div class="pophover-calendar-container" nbPopoverPlacement="left" [nbPopover]="template"  nbPopoverTrigger="hint" >
      <div class="date-event">{{ arg.timeText }}</div>


      <div *ngIf="IS_REAL_ESTATE">{{ arg.event.title  }}</div>
      <div *ngIf="!IS_REAL_ESTATE">{{  arg.event.extendedProps?.page?.title}}</div>

      <ng-template #template >

        <div class="property-pophover" *ngIf="arg.event.extendedProps.property?.id">
          <div class="row g-0">
            <div class="col-4">
              <img src="{{ arg.event.extendedProps.property?.photo?.full_url }}" style="width:100%" />
            </div>

            <div class="col-8">

              <div style="padding:5px;">
                <nb-tag-list size="tiny">
                  <nb-tag size="tiny" status="basic" appearance="filled" text="Pending" *ngIf="arg.event.extendedProps.property?.is_available == 0"></nb-tag>
                  <nb-tag size="tiny" status="success" appearance="filled" text="Available" *ngIf="arg.event.extendedProps.property?.is_available == 1"></nb-tag>
                  <nb-tag size="tiny" status="danger" appearance="filled" text="Sold" *ngIf="arg.event.extendedProps.property?.is_available == 2"></nb-tag>
                  <nb-tag size="tiny" status="basic" appearance="filled" text="Out of market" *ngIf="arg.event.extendedProps.property?.is_available == 3"></nb-tag>
                </nb-tag-list>

                <div>
                  <strong>Ref:</strong> {{arg.event.extendedProps.property?.reference}}
                </div>

                <div>
                  <span *ngIf="arg.event.extendedProps.property?.price"><strong>Price: </strong>{{ arg.event.extendedProps.property?.price | currencyFormat }}</span>
                </div>

                <div>
                  <span *ngIf="arg.event.extendedProps.property?.bathrooms"><strong>Location:</strong> {{ arg.event.extendedProps.property?.location?.name }}</span>
                </div>


                <div>
                  <span *ngIf="arg.event.extendedProps.property?.bathrooms"><strong>Bed.:</strong> {{ arg.event.extendedProps.property?.bathrooms }}</span>
                </div>

                <div>
                  <span *ngIf="arg.event.extendedProps.property?.bathrooms"><strong>Bath.:</strong> {{ arg.event.extendedProps.property?.bathrooms }}</span>
                </div>

              </div>

            </div>

          </div>
        </div>

        <div class="property-pophover border-top" *ngIf="arg.event.extendedProps.contacts?.length">
          <div class="row">
            <div class="col-12">
              <h3 *ngIf="arg.event.extendedProps.contacts?.length > 1">Attendees</h3>
              <h3 *ngIf="arg.event.extendedProps.contacts?.length <= 1">Attendee</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div *ngFor="let contact of arg.event.extendedProps.contacts">
                <span class="popHover" nbPopoverPlacement="left" [nbPopover]="template" nbPopoverTrigger="click">
                    <nb-user size="medium" name="{{contact?.address?.full_name}}"></nb-user>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </ng-template>
</full-calendar>

