<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit()" *ngIf="isLoaded" enctype="multipart/form-data">


  <div class="row" *ngIf="!uploadProgress">
    <div class="col-12 col-md-7"  *ngIf="page?.isArticle=='podcasts'">
      <nb-tabset (changeTab)="selectTab($event)">
        <nb-tab *ngFor="let locale of locales; let i = index" tabTitle="{{ locale }}" tabId="{{ locale }}"
                style="padding:20px 0 20px 0!important;">
          <ng-template nbTabTitle>
            <img src="/assets/images/flags/{{ locale }}.svg" width="24"/>
            <nb-icon *ngIf="!isFormValidForLocale(getTitleFormGroup(i))"
                     [icon]="isFormValidForLocale(getTitleFormGroup(i)) ? 'checkmark-circle-2-outline' : 'alert-triangle-outline'"
                     [ngClass]="{'red-color': !isFormValidForLocale(getTitleFormGroup(i)), 'green-color': isFormValidForLocale(getTitleFormGroup(i))}">
            </nb-icon>
          </ng-template>

          <nb-card [formGroup]="getTitleFormGroup(i)">
            <nb-card-body>
              <nb-tabset>
                <nb-tab style="padding:0!important;">
                  <ng-template nbTabTitle>
                    Main detail
                  </ng-template>


                  <div>
                    <div class="mb-3" style="margin-top: 20px;">
                      <div class="form-floating">
                        <input type="text" class="form-control" id="{{ locale + '.title' }}"
                               [formControl]="getTitleFormControl(locale, 'title')" placeholder="Title">
                        <label for="{{ locale + '.title' }}">Title*</label>
                      </div>
                    </div>


                    <div class="mb-3">
                      <label for="{{ locale + '.description' }}">Short description</label>
                      <textarea #contentTextarea class="form-control auto-resize" id="{{ locale + '.description' }}"
                                [formControl]="getTitleFormControl(locale, 'description')"
                                (input)="adjustTextareaHeight(contentTextarea)"
                                placeholder="Description"></textarea>
                    </div>

                    <div class="mb-3">
                      <app-editor-widget [locale]="locale"
                                         [formControl]="getTitleFormControl(locale, 'content')"></app-editor-widget>
                    </div>

                  </div>


                </nb-tab>
              </nb-tabset>
            </nb-card-body>

            <nb-card-footer>

              <div class="row">
                <div class="col-6">
                  <button nbButton status="danger" class="btn-ico-w" type="button" *ngIf="id" (click)="cancel()">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                    <span>Delete</span>
                  </button>
                </div>


                <div class="col-12 col-md-6" style="text-align:right;">
                  <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                    <nb-icon icon="save-outline"></nb-icon>
                    <span>Save</span>
                  </button>
                </div>

              </div>


            </nb-card-footer>

          </nb-card>
        </nb-tab>

      </nb-tabset>


    </div>


    <div [ngClass]="{'col-12': true, 'col-md-5': page?.isArticle !== 'podcast'}">


      <nb-card class="mb-3" *ngIf="page?.isArticle=='podcasts'">
        <nb-card-header>
          Image
        </nb-card-header>
        <nb-card-body>
          <div class="upload-container">
            <input type="file" class="fileInput" id="fileInput" (change)="handleFileInput($event, 'image')">
            <label for="fileInput" class="upload-label">
              <img [src]="pageFormGroup?.get('original_photo')?.value || image_url || '/assets/images/image.png'"  alt="Upload Image">
            </label>
          </div>
        </nb-card-body>
      </nb-card>


      <nb-card>
        <nb-card-header>
          Audio
        </nb-card-header>

        <nb-card-body>
          <input type="file" id="fileUpload" (change)="onFileChange($event)">


          <div class="p-20" *ngIf="audio_url">
          <audio controls>
            <source src="{{audio_url}}" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
          </div>
        </nb-card-body>


        <nb-card-footer  *ngIf="page?.isArticle=='podcast'">

          <div class="row">
            <div class="col-6">
              <button nbButton status="danger" class="btn-ico-w" type="button" *ngIf="id" (click)="cancel()">
                <nb-icon icon="trash-2-outline"></nb-icon>
                <span>Delete</span>
              </button>
            </div>


            <div class="col-12 col-md-6" style="text-align:right;">
              <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                <nb-icon icon="save-outline"></nb-icon>
                <span>Save</span>
              </button>
            </div>

          </div>


        </nb-card-footer>

      </nb-card>





    </div>
  </div>


  <nb-card *ngIf="uploadProgress">
    <nb-card-header>Upload Progress</nb-card-header>
    <nb-card-body>
      <nb-progress-bar [value]="uploadProgress" status="primary" [striped]="true"></nb-progress-bar>
      <div *ngIf="uploadProgress > 0">{{ uploadProgress }}%</div>
    </nb-card-body>
  </nb-card>


</form>
