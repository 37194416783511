import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../services/toast/toast.service";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {AdvertisementsService} from "../../../services/advertisements.service";
import {ConfirmComponent} from "../../../../../components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-form-advertisement',
  templateUrl: './form-advertisement.component.html',
  styleUrls: ['./form-advertisement.component.scss']
})
export class FormAdvertisementComponent  implements OnInit {

  @Input() pageId: any;
  @Input() locales: any;
  pageFormGroup!: FormGroup;

  public editor!: any;

  constructor(
    private dialog: MatDialog,
    private advertisementsService: AdvertisementsService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    @Optional() public _NzDrawerService: NzDrawerService,
    private drawerRef: NzDrawerRef,
  ) {
  }

  isLoaded: boolean = false;


  image_url!: any;

  ngOnInit(): void {

    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      link_to: [null],
      title: [null, Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
      location: [''],
      image: [''],
      image_updated: [false],
    });
    this.loadPage();



  }

  public page!: any;
  dropdownPages!: any[];
  selectedParentId: any;

  loadPage() {
    if(this.pageId) {
      this.advertisementsService.getPage(this.pageId).subscribe(datas => {
        this.page = datas?.slider;

        this.selectedParentId = this.page.link_to;
        if (this.page) {


          this.pageFormGroup.get('id')?.setValue(this.page?.id)
          this.pageFormGroup.get('title')?.setValue(this.page?.title)
          this.pageFormGroup.get('link_to')?.setValue(this.page?.link_to)



          if (this.page?.start) {
            const fromDate = new Date(this.page.start.replace(' ', 'T') + 'Z');
            this.pageFormGroup.get('start')?.setValue(fromDate);
          }

          if (this.page?.end) {
            const endDate = new Date(this.page.end.replace(' ', 'T') + 'Z');
            this.pageFormGroup.get('end')?.setValue(endDate);
          }



          this.image_url = this.page?.image_url;

          this.isLoaded = true;

        }
      });
    } else {
      this.isLoaded = true;
    }
  }


  selectedImageFile: File | undefined;

  handleFileInput(event: any, fieldName: string): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          if (typeof result === 'string') { // Vérification du type
            if (fieldName === 'image') {
              // Convertir l'image en format jpg
              this.convertToJpg(result, fieldName);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }


  convertToJpg(base64Data: string, fieldName: string): void {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg'); // Convertir l'image en jpg
        if (fieldName === 'image') {
          // Stockez temporairement l'image sélectionnée
          this.selectedImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de l'image principale dans le formulaire
          this.pageFormGroup.get('image')?.setValue(dataURL);
          this.pageFormGroup.get('image_updated')?.setValue(true); // Marquez la grande image comme mise à jour
        }
      }
    };
    img.src = base64Data;
  }

  dataURItoBlob(dataURI: string): File {
    // Diviser la chaîne de données en deux parties : le type MIME et les données réelles
    const [type, byteString] = dataURI.split(',');

    // Convertir la chaîne base64 en tableau de nombres entiers non signés
    const binary = atob(byteString);

    // Créer un tableau tampon pour stocker les octets binaires
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    // Créer un objet Blob à partir des données binaires
    const blob = new Blob([array], {type: type});

    // Créer un objet File à partir du Blob
    const fileName = 'image.jpg'; // Nom de fichier par défaut
    return new File([blob], fileName, {type: type});
  }



  isFormValidForLocale(formGroup: FormGroup): boolean {
    return formGroup.valid; // Vous pouvez ajouter des conditions supplémentaires si nécessaire
  }



  onSubmit(): void {
    if (this.pageFormGroup.valid) {
      const pageData = this.pageFormGroup.value;

      this.advertisementsService.createOrUpdatePage(pageData).subscribe(
        response => {
          this.pageFormGroup.get('image_updated')?.setValue(false); // Marquez la grande image comme mise à jour


          this.toastService.showToast('Page updated successfully', 'Success!', 'success');
          this.drawerRef.close();

          console.log('Page créée ou mise à jour avec succès', response);
        },
        error => {
          // Gérer les erreurs
          console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
        }
      );
    } else {
      // Le formulaire n'est pas valide, affichez un message d'erreur ou prenez d'autres mesures nécessaires
      console.error('Le formulaire est invalide. Veuillez vérifier les champs et réessayer.');
    }
  }

  public locale!:any;
  selectTab($event: any): void {
    this.locale = $event.tabId;
  }

  onDateChange(selectedDate: Date, controlName: string): void {
    if (selectedDate) {
      // Obtenir l'année, le mois et le jour
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Mois commence à 0
      const day = String(selectedDate.getDate()).padStart(2, '0'); // Jour du mois

      // Construire la date au format yyyy-MM-dd
      const formattedDate = `${year}-${month}-${day}`;

      // Mettre à jour le FormControl avec la date formatée
      this.pageFormGroup.get(controlName)?.setValue(formattedDate);
    } else {
      // Réinitialiser le FormControl si la date est nulle
      this.pageFormGroup.get(controlName)?.setValue(null);
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this page?',
        text: 'This action is irreversible and will permanently delete the page.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.advertisementsService.delete(this.pageFormGroup?.get('id')?.value).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );
      }
    });
  }
}
