import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from "../../../services/auth/auth.service";
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PodcastsService {
  private baseUrl!: string;
  private apiUrl!: string;
  private apiPageUrl!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Inject the authentication service
  ) {
    // Get the selected domain from the authentication service
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/podcasts`;
      this.apiPageUrl = `${selectedDomain.domainName}/api/pages`;
      this.baseUrl = `${selectedDomain.domainName}/podcasts`;
    } else {
      console.error('Selected domain is not set.');
    }

  }

  listPages(parent:any): Observable<any> {
    if(parent ==null || parent =='null') {
      parent = '';
    } else {
      parent = '/' + parent;
    }
    return this.http.get<any>(this.apiUrl + parent);
  }

  pagination(pageId: any, page: number = 1, perPage: number = 15): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + pageId);
  }

  getPage(id:any): Observable<any> {
    return this.http.get<any>(this.apiPageUrl + '/get/' + id);
  }

  getPodcast(id:any): Observable<any> {
    if(id==null) {
      id = '';
    }
    return this.http.get<any>(this.apiUrl + '/get/' + id);
  }

  getUniquePodcast(pageId:any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/getUnique/' + pageId);
  }


  // Method to update page positions
  updateNodes(nodes:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-ranks`, {'data' : nodes});
  }


  createOrUpdatePage(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createOrUpdatPodcast`, pageData);
  }

  uploadImage(imageData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/upload-image`, imageData);
  }

  getuploadUrl() {
    return `${this.baseUrl}/upload-image`;
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/delete/` + id);
  }


  // Method to update page positions
  translate(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/translate`, pageData);
  }


  uploadAudio(file: File, podcastId: number | null): Observable<{ progress: number, body?: any }> {
    const formData = new FormData();
    formData.append('file', file);
    if (podcastId !== null) {
      formData.append('podcastId', podcastId.toString()); // Inclure le podcastId dans le formulaire
    }

    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });

    return this.http.post<{ progress: number, body?: any }>(this.apiUrl + '/upload-audio', formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const total = event.total ?? 1; // Utilisez 1 comme valeur par défaut si event.total est undefined
            return { progress: Math.round(100 * (event.loaded || 0) / total) };
          case HttpEventType.Response:
            return { progress: 100, body: event.body };
          default:
            return { progress: 0 };
        }
      })
    );
  }


}
