import {ChangeDetectorRef, Component, Input, Optional, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContactsComponent} from "../../../../modules/contacts/pages/contacts/contacts.component";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {ModalFormContactComponent} from "../../../../modules/contacts/components/modal-form-contact/modal-form-contact.component";
import {HttpClient} from "@angular/common/http";
import {NbDateService} from "@nebular/theme";
import {format, parseISO, set} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {DatePipe} from "@angular/common";
import * as _moment from 'moment';
import * as moment from 'moment-timezone';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmComponent} from "../../../confirm/confirm.component";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import {PropertiesService} from "../../../../modules/properties/services/properties.service";
import {EventService} from "../../../../services/events/event.service";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";


@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent {

  private destroy$ = new Subject<void>();
  private previousPropertyId: any;


  formGroup: FormGroup;
  isLoaded = false;

  @Input() contact!: any;
  @Input() selectedDate!: any;
  @Input() eventId: any;
  @Input() addressesCategories: any;
  @Input() genders: any;
  @Input() priorities: any;
  @Input() showOnly: any;
  @Input() PHONE_TYPES: any;
  @Input() EMAIL_TYPES: any;
  @Input() SOCIALS: any;
  @Input() programs: any;


  property!: any;
  contacts: any[] = [];

  @Input() currentDate!: any;
  durations: { id: number; name: string }[] = [];
  IS_REAL_ESTATE: any;

  constructor(
    private eventService: EventService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Optional() public _NzDrawerService: NzDrawerService,
    @Optional() public _NzDrawerRef: NzDrawerRef,
    private http: HttpClient,
    dateService: NbDateService<Date>,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private dialog: MatDialog,
    private propertiesService: PropertiesService,
    private configurationService: GetConfigurationService,

  ) {

    this.refreshConfiguration();

    this.generateDurations();



    if(this.IS_REAL_ESTATE) {
      this.formGroup = this.fb.group({
        id: [null],
        property_id: [null],
        date: ['', [Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/)]], // Validation pour le format 'YYYY-MM-DD'
        time: [null, Validators.required],
        duration: [null, [Validators.required, Validators.pattern(/^\d+$/), this.durationValidator]],
        title: [null, Validators.required],
        description: [null],
        contactsArray: this.fb.array([]),
      });
    } else {
      this.formGroup = this.fb.group({
        id: [null],
        date: ['', [Validators.required, Validators.pattern(/^\d{4}-\d{2}-\d{2}$/)]], // Validation pour le format 'YYYY-MM-DD'
        time: [null, Validators.required],
        duration: [null, [Validators.required, Validators.pattern(/^\d+$/), this.durationValidator]],
        programm_id: [null, [Validators.required, Validators.pattern(/^\d+$/), this.durationValidator]],
        title: [null],
        description: [null],

      });
    }


    if(this.IS_REAL_ESTATE) {
      this.formGroup.get('property_id')?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(newValue => {
          if (this.previousPropertyId !== newValue) {
            this.showAlertPlaceOwner();
            this.previousPropertyId = newValue;
          }
        });
    }

  }

  refreshConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.IS_REAL_ESTATE = datas?.data?.IS_REAL_ESTATE;
      this.programs = datas?.data?.programs;
      this.cdr.detectChanges();
    });
  }



  durationValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value !== null && (isNaN(value) || value <= 0)) {
      return { 'invalidDuration': true };
    }
    return null;
  }


  ngOnInit() {


    if(!this.eventId) {
      this.isLoaded = true;
      const originalDate = this.selectedDate.start;
      const timeZone = 'Europe/Paris';

      const zonedDate = utcToZonedTime(originalDate, timeZone);

      this.currentDate = set(new Date(0), {
        year: zonedDate.getFullYear(),
        month: zonedDate.getMonth(),
        date: zonedDate.getDate(),
        hours: zonedDate.getHours(),
        minutes: zonedDate.getMinutes(),
        seconds: zonedDate.getSeconds(),
        milliseconds: zonedDate.getMilliseconds(),
      });

      const durationInMinutes = moment(this.selectedDate.end).diff(moment(this.selectedDate.start), 'minutes');
      this.formGroup.get('duration')?.setValue(durationInMinutes)
      this.formGroup.get('duration')?.updateValueAndValidity()

      const currentTime = moment(this.selectedDate.start).format('HH:mm');
      this.formGroup.get('time')?.setValue(currentTime)
      this.formGroup.get('time')?.updateValueAndValidity()


      this.formGroup.get('date')?.setValue(moment(this.currentDate).format('YYYY-MM-DD'))
      this.formGroup.get('date')?.updateValueAndValidity()

      if(this.IS_REAL_ESTATE) {
        this.formGroup.setControl('contactsArray', this.buildContactsArray());
      }



    } else {

      this.eventService.getEvent(this.eventId).subscribe(
        (response) => {
          this.isLoaded = true;

          if(this.IS_REAL_ESTATE) {
            this.property = response?.data?.property;
            this.previousPropertyId = response?.data?.property_id;
            this.formGroup.get('id')?.setValue(this.eventId)

            this.formGroup.patchValue(response?.data);

            this.formGroup.setControl('contactsArray', this.buildContactsArray());
            const contactsArray = this.formGroup.get('contactsArray') as FormArray;
            const contactKeys = response?.data?.contactsArray;
            if (contactKeys) {
              contactKeys.forEach((key : any) => {
                this.contacts.push(key?.address);
                contactsArray.push(this.fb.control(key.contact_id));
              });
            }

            this.formGroup.setControl('contactsArray', this.buildContactsArray());
            this.formGroup.updateValueAndValidity();
          } else {

            this.formGroup.get('id')?.setValue(this.eventId)
            this.formGroup.patchValue(response?.data);

            this.formGroup.get('programm_id')?.setValue(response?.data?.page?.id)

          }

        },
        (error) => {
          console.error('Error updating event:', error);
          // Gérez les erreurs ici
        }
      );

    }

    if(this.IS_REAL_ESTATE) {
      if (this.contact) {
        this.contacts.push(this.contact);
        this.formGroup.setControl('contactsArray', this.buildContactsArray());
        this.formGroup.updateValueAndValidity();
      }
    }
  }


  onSubmit() {
    if (this.formGroup.valid) {

      if(this.IS_REAL_ESTATE) {
        this.eventService.addOrUpdateEvent(this.formGroup.value).subscribe(
          (response) => {
            this._NzDrawerRef.close();
          },
          (error) => {
            console.error('Error while saving/updating event', error);
            // Ajoutez ici d'autres logiques en cas d'erreur
          }
        );
      } else {

        this.eventService.addOrUpdateProgram(this.formGroup.value).subscribe(
          (response) => {
            this._NzDrawerRef.close();
          },
          (error) => {
            console.error('Error while saving/updating event', error);
          }
        );

      }
    }
  }


  buildContactsArray(): FormArray {
    return this.fb.array(this.contacts.map(contact => this.fb.control(contact.id)));
  }


  onDateChange(newDate: Date): void {

    this.formGroup.get('date')?.setValue(moment(newDate).format('YYYY-MM-DD'));
    this.formGroup.get('date')?.updateValueAndValidity();
  }

  generateDurations(): void {
    const intervalleMinutes = 15;
    const nombreHeures = 24 * 60 / intervalleMinutes;

    for (let i = 0; i <= nombreHeures; i++) {
      const minuteOfDay = i * intervalleMinutes;
      const hour = moment().startOf('day').minutes(minuteOfDay);

      if (minuteOfDay) {
        this.durations.push({
          id: minuteOfDay,
          name: hour.format('HH:mm'),
        });
      }
    }
  }


  getFormControl<T extends AbstractControl>(name: string): T | null {
    const control = this.formGroup?.get(name);
    return control as T | null;
  }

  onFormControlChange(controlName: string, newValue: any | null): void {
    this.updateFormControlValue(controlName, newValue);
  }

  updateFormControlValue(controlName: string, newValue: any | null): void {
    const control = this.formGroup.get(controlName);
    if (control) {
      control.setValue(newValue);
      control.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  showAlertPlaceOwner() {

    if (this.formGroup.get('property_id')?.value) {
      this.propertiesService.getPropertyDetails(this.formGroup.get('property_id')?.value).subscribe(
        (response) => {
          if (response?.data?.owner) {
            const ownerExists = this.contacts.some(contact => contact.id === response?.data?.owner?.id);

            if(!ownerExists) {
              const dialogRef = this.dialog.open(ConfirmComponent, {
                width: '500px',
                data: {
                  title: 'Include the owner in the attendees list?',
                  text: 'Would you like to add the property to the list of attendees for the appointment?',
                  class: 'warning',
                  no: 'No, close!',
                  yes: 'Yes!',
                }
              });

              dialogRef.afterClosed().subscribe((info: any) => {
                if (info) {
                  this.contacts.push(response?.data?.owner);
                  this.formGroup.setControl('contactsArray', this.buildContactsArray());
                }
              });
            }
          }
        },
        (error) => {
          // Gérer les erreurs
        }
      );
    }


  }


  cancel() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this event?',
        text: 'This action is irreversible and will permanently delete the event.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.eventService.deleteEvent(this.eventId).subscribe(
          (response) => {

            this._NzDrawerRef.close(true);
          }
        );
      }
    });
  }
}
