import {ChangeDetectorRef, Component, Input, OnInit, Optional} from '@angular/core';
import {Share} from "../../../../class/share";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {GetConfigurationService} from "../../../../services/getConfiguration/get-configuration.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {PodcastsService} from "../../services/podcasts.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../services/toast/toast.service";
import {MatDialog} from "@angular/material/dialog";
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";

@Component({
  selector: 'app-podcast-form',
  templateUrl: './podcast-form.component.html',
  styleUrls: ['./podcast-form.component.scss']
})
export class PodcastFormComponent    implements OnInit {
  @Input() locales: any;
  @Input() pageId: any;
  @Input() id: any;
  page: any;
  podcast: any;
  paginationData: any;
  audio_url: any;
  parentId: any = '';
  pageFormGroup!: FormGroup;
  titleFormArray!: FormArray;
  public locale!:any;
  fileToUpload: File | null = null;
  uploadProgress: number = 0;
  isLoaded = false;
  selectedImageFile: File | undefined;
  image_url!: any;

  createTitleFormGroup(locale: any): FormGroup {
    return this.formBuilder.group({
      id: [''],
      locale: [locale],
      title: ['', Validators.required],
      description: [''],
      content: [''],
    });
  }


  constructor(
    @Optional() public _NzDrawerService: NzDrawerService,
    private configurationService: GetConfigurationService,
    private podcastsService: PodcastsService,

    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private drawerRef: NzDrawerRef,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
  }



  ngOnInit(): void {

    const titleFormArrayControls = this.locales.map((locale: any) => this.createTitleFormGroup(locale));
    this.titleFormArray = this.formBuilder.array(titleFormArrayControls);

    this.locales.forEach((locale: any) => {
      if(!this.locale) {
        this.locale = locale;
      }
      this.titleFormArray = this.formBuilder.array(
        this.locales.map((locale: any) => this.createTitleFormGroup(locale))
      );
    });


    this.pageFormGroup = this.formBuilder.group({
      id: [null],
      page_id: [this.pageId],
      audio_src: ['', Validators.required], // Rendre audio_src requis
      original_photo: [''],
      original_photo_updated: [''],
      titleForm: this.titleFormArray,
    });

    if (this.id) {
      this.podcastsService.getPodcast(this.id).subscribe(
        (data) => {
          this.podcast = data?.podcast;

          this.pageFormGroup.get('id')?.setValue(this.podcast?.id);
          this.pageFormGroup.get('page_id')?.setValue(this.podcast?.page_id);
          this.pageFormGroup.get('audio_src')?.setValue(this.podcast?.audio_src);
          this.pageFormGroup.get('original_photo')?.setValue(this.podcast?.image_url);

          this.audio_url = this.podcast?.audio_url;
          this.image_url = this.podcast?.original_photo;

          this.podcast.translations.forEach((translation: any) => {
            // Trouvez l'index de la traduction dans le tableau des langues
            const index = this.locales.findIndex((locale: any) => locale === translation.locale);
            if (index !== -1) {
              // Si la traduction est trouvée, patchez les valeurs dans le FormGroup correspondant
              this.titleFormArray.at(index).patchValue(translation);
            }
          });


          this.isLoaded = true;
        },
        (error) => {
          console.error('Error fetching page data:', error);
        }
      );
    } else {

      if(this.page?.isArticle == 'podcast') {
        this.podcastsService.getUniquePodcast(this.page?.id).subscribe(
          (data) => {

            if(data?.podcast) {
              this.podcast = data?.podcast;

              this.pageFormGroup.get('id')?.setValue(this.podcast?.id);
              this.pageFormGroup.get('page_id')?.setValue(this.podcast?.page_id);
              this.pageFormGroup.get('audio_src')?.setValue(this.podcast?.audio_src);
              this.pageFormGroup.get('original_photo')?.setValue(this.podcast?.image_url);

              this.audio_url = this.podcast?.audio_url;
              this.image_url = this.podcast?.original_photo;

              this.podcast.translations.forEach((translation: any) => {
                // Trouvez l'index de la traduction dans le tableau des langues
                const index = this.locales.findIndex((locale: any) => locale === translation.locale);
                if (index !== -1) {
                  // Si la traduction est trouvée, patchez les valeurs dans le FormGroup correspondant
                  this.titleFormArray.at(index).patchValue(translation);
                }
              });

            } else {
              this.pageFormGroup.get('page_id')?.setValue(this.pageId);
            }

            this.isLoaded = true;
          },
          (error) => {
            console.error('Error fetching page data:', error);
          }
        );
      }

      const defaultTitle = `${this.page?.title} - ${this.getFormattedDate()}`;
      this.titleFormArray.controls.forEach((control: AbstractControl, index: number) => {
        if (control instanceof FormGroup) {
          control.get('title')?.setValue(defaultTitle);
        }
      });

      this.isLoaded = true;
    }
  }

  getFormattedDate(): string {
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const now = new Date();
    const dayOfWeek = daysOfWeek[now.getDay()];
    const dayOfMonth = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
  }


  isFormValidForLocale(formGroup: FormGroup): boolean {
    return formGroup.valid; // Vous pouvez ajouter des conditions supplémentaires si nécessaire
  }

  getTitleFormGroup(index: any): FormGroup {
    return this.titleFormArray.at(index) as FormGroup;
  }

  selectTab($event: any): void {
    this.locale = $event.tabId;
  }

  getTitleFormControl(locale: string, fieldName: string): FormControl {
    return this.titleFormArray.controls.find(control => control.get('locale')?.value === locale)?.get(fieldName) as FormControl;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 2) + 'px';
  }





  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
    this.pageFormGroup?.get('audio_src')?.setValue(this.selectedFile);
  }

  onSubmit() {
    if (this.pageFormGroup.valid) {
      const pageData = this.pageFormGroup.value;
      this.uploadPageData(pageData);
    } else {
      console.error('Le formulaire est invalide. Veuillez vérifier les champs et réessayer.');
    }
  }
  selectedFile: File | null = null;

  private uploadPageData(pageData: any) {
    this.podcastsService.createOrUpdatePage(pageData).subscribe(
      response => {
        this.pageFormGroup.get('image_updated')?.setValue(false);
        this.pageFormGroup.get('big_image_updated')?.setValue(false);

        if (this.selectedFile) {
          this.uploadAudio(this.selectedFile, response.podcast?.id );
        } else {
          this.toastService.showToast('Page updated successfully', 'Success!', 'success');
          this.drawerRef.close();
        }
      },
      error => {
        console.error('Une erreur s\'est produite lors de la création ou de la mise à jour de la page', error);
      }
    );
  }

  private uploadAudio(file: File, id:any) {
    this.podcastsService.uploadAudio(file, id).subscribe(
      event => {
        this.uploadProgress = event.progress;

        if (event.progress === 100 && event.body) {
          this.pageFormGroup.get('audio_src')?.setValue(event.body.fileName); // Supposez que l'API retourne le nom du fichier
          this.toastService.showToast('Audio uploaded successfully', 'Success!', 'success');
          this.drawerRef.close();
        }
      },
      error => {
        console.error('Upload failed', error);
        this.toastService.showToast('Upload failed. Please try again.', 'Error', 'danger');
      }
    );
  }


  handleFileInput(event: any, fieldName: string): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          if (typeof result === 'string') { // Vérification du type
            if (fieldName === 'image') {
              // Convertir l'image en format jpg
              this.convertToJpg(result, fieldName);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }


  convertToJpg(base64Data: string, fieldName: string): void {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg'); // Convertir l'image en jpg
        if (fieldName === 'image') {
          // Stockez temporairement l'image sélectionnée
          this.selectedImageFile = this.dataURItoBlob(dataURL);
          // Mettez à jour le chemin de l'image principale dans le formulaire
          this.pageFormGroup.get('original_photo')?.setValue(dataURL);
          this.pageFormGroup.get('original_photo_updated')?.setValue(dataURL);
        }
      }
    };
    img.src = base64Data;
  }

  dataURItoBlob(dataURI: string): File {
    // Diviser la chaîne de données en deux parties : le type MIME et les données réelles
    const [type, byteString] = dataURI.split(',');

    // Convertir la chaîne base64 en tableau de nombres entiers non signés
    const binary = atob(byteString);

    // Créer un tableau tampon pour stocker les octets binaires
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    // Créer un objet Blob à partir des données binaires
    const blob = new Blob([array], {type: type});

    // Créer un objet File à partir du Blob
    const fileName = 'image.jpg'; // Nom de fichier par défaut
    return new File([blob], fileName, {type: type});
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Would you like to delete this podcast?',
        text: 'This action is irreversible and will permanently delete the podcast.',
        class: 'danger',
        no: 'No, close!',
        yes: 'Yes!',
      }
    });

    dialogRef.afterClosed().subscribe((info: any) => {
      if (info) {
        this.podcastsService.delete(this.id).subscribe(
          (response) => {
            this.drawerRef.close(true);
          }
        );
      }
    });
  }

}
