<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">
    <nb-action>
      <h1 class="title-page">{{ page?.title}}</h1>
    </nb-action>
  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addPodcast(null)">
        <nb-icon icon="plus-outline"></nb-icon>
        <span>Add new podcast</span>
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>

<div class="p-0">

  <nb-card>
    <nb-card-header>Podcasts List</nb-card-header>
    <nb-card-body>

      <ng-container *ngIf="!paginationData?.length && !isLoading">
        <div class="empty">No podcasts on this page</div>
      </ng-container>

      <nb-list>
        <nb-list-item *ngFor="let podcast of paginationData" class="podcast-item">
          <div class="podcast-info">
            <strong>{{ podcast.title }}</strong>
          </div>
          <button nbButton ghost (click)="editPodcast(podcast.id)" class="edit-button">
            <nb-icon icon="edit-outline"></nb-icon>
          </button>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>



</div>
