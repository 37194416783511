import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PropertiesService} from "../../services/properties.service";
import { HttpEventType } from '@angular/common/http';
import {CdkDragDrop, CdkDragMove, CdkDragEnter, CdkDragPlaceholder, CdkDragPreview, moveItemInArray} from '@angular/cdk/drag-drop';
import {Subscription} from "rxjs";
import {NbToastrService} from "@nebular/theme";
import {ToastService} from "../../../../services/toast/toast.service";
import {PageService} from "../../../pages/services/pages.service";

@Component({
  selector: 'app-images-upload',
  templateUrl: './images-upload.component.html',
  styleUrls: ['./images-upload.component.scss']
})
export class ImagesUploadComponent {
  @Input() propertyId: any;
  @Input() pageId: any;
  @Input() photos: any;
  previewTemplate: CdkDragPreview | null = null;
  placeholderTemplate: CdkDragPlaceholder | null = null;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  public items: Array<number> = [];

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };


  constructor(
    private cdr: ChangeDetectorRef,
    private propertiesService: PropertiesService,
    private PageService: PageService,
    private toastrService: NbToastrService,
    private toastService: ToastService,
  ) {}

  onFileChange(event: any): void {
    const files: FileList = event.target.files;

    // Use Promise.all to wait for all uploadImage promises to complete
    Promise.all(Array.from(files).map(file => this.uploadImage(file)))
      .then(() => {
        // All photos are uploaded, you can proceed with other actions if needed
      })
      .catch(error => console.error('Error uploading photos:', error));
  }

  private uploadImage(file: File): void {
    const formData = new FormData();
    formData.append('files[]', file);


    if(this.propertyId) {
      formData.append('property_id', this.propertyId.toString());
      formData.append('reference_table', 'properties');

      const upload$ = this.propertiesService.uploadImages(formData);

      upload$.subscribe(
        (response) => {
          if (response && response.data) {
            console.log('Upload complete for:', file.name);
            this.photos = response.data;
            this.cdr.detectChanges()
            this.valueChange.emit(this.photos);
          }
        },
        (error) => {
          console.error('Upload error:', error);
        }
      );
    }

    if(this.pageId) {
      formData.append('page_id', this.pageId.toString());
      formData.append('reference_table', 'pages');

      const upload$ = this.PageService.uploadImages(formData);

      upload$.subscribe(
        (response) => {
          if (response && response.data) {
            console.log('Upload complete for:', file.name);
            this.photos = response.data;
            this.cdr.detectChanges()
            this.valueChange.emit(this.photos);
          }
        },
        (error) => {
          console.error('Upload error:', error);
        }
      );
    }

  }


  onImageDrop(event: any): void {
    event.preventDefault();
    const files: FileList = event.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.photos.push(files[i]);
      this.uploadImage(files[i]);
    }
  }


  onDropComplete(event: CdkDragDrop<any[]>): void {
    // Reorder the array based on the drag and drop
    moveItemInArray(this.photos, event.previousIndex, event.currentIndex);

    this.photos.forEach((photo:any, index:any) => {
      photo.rank = index;
    });

    const formData = new FormData();

    if(this.propertyId) {
      formData.append('property_id', this.propertyId.toString());
      formData.append('reference_table', 'properties');
    }

    if(this.pageId) {
      formData.append('page_id', this.pageId.toString());
      formData.append('reference_table', 'pages');
    }

    formData.append('photos', JSON.stringify(this.photos)); // Assuming your service can handle this format

    if(this.propertyId) {
      this.propertiesService.updateRank(formData).subscribe(
        (response) => {
          console.log('Rank update successful:', response);
          this.cdr.detectChanges()
          this.valueChange.emit(this.photos);
        },
        (error) => {

          console.error('Rank update error:', error);
          // Handle the error if needed
        }
      );
    }



    if(this.pageId) {
      this.PageService.updateRank(formData).subscribe(
        (response) => {
          console.log('Rank update successful:', response);
          this.cdr.detectChanges()
          this.valueChange.emit(this.photos);
        },
        (error) => {

          console.error('Rank update error:', error);
          // Handle the error if needed
        }
      );
    }



  }


  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    console.log('dragEntered', { dragIndex, dropIndex });

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.photos, dragIndex, dropIndex);

    }
  }

  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped(event: CdkDragDrop<number>) {
    this.photos.forEach((photo:any, index:any) => {
      photo.rank = index;
    });

    const formData = new FormData();


    if(this.propertyId) {
      formData.append('property_id', this.propertyId.toString());
      formData.append('reference_table', 'properties');
    }
    if(this.pageId) {
      formData.append('page_id', this.pageId.toString());
      formData.append('reference_table', 'pages');
    }

    formData.append('photos', JSON.stringify(this.photos));

    this.propertiesService.updateRank(formData).subscribe(
      (response) => {
        this.valueChange.emit(this.photos);
        this.toastService.showToast('Position update successful:', 'Success!', 'success');
      },
      (error) => {
        console.error('Rank update error:', error);
      }
    );

  }

  deleteImage(photoId: any) {


    if(this.propertyId) {
      this.propertiesService.deletePhoto(photoId, this.propertyId).subscribe(
        (response) => {
          this.photos = response.data;
          this.cdr.detectChanges()
          this.valueChange.emit(this.photos);
          this.toastService.showToast('Photo deleted successful:', 'Success!', 'success');

        },
        (error) => {

        }
      );
    }

    if(this.pageId) {
      this.PageService.deletePhoto(photoId, this.pageId).subscribe(
        (response) => {
          this.photos = response.data;
          this.cdr.detectChanges()
          this.valueChange.emit(this.photos);
          this.toastService.showToast('Photo deleted successful:', 'Success!', 'success');

        },
        (error) => {

        }
      );
    }


  }
}
