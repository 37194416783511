<form [formGroup]="pageFormGroup" (ngSubmit)="onSubmit('page')" *ngIf="isLoaded" enctype="multipart/form-data">

  <nb-card>
    <nb-card-body>


      <div class="row" *ngIf="locales.length > 1">
        <div class="col-12 col-md-8">
          <button nbButton status="primary" size="tiny" class="btn-ico-w" (click)="translate()" type="button">
            <nb-icon icon="globe-outline"></nb-icon>
            <span>Automatic translation</span>
          </button>

        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8">
          <nb-tabset (changeTab)="selectTab($event)">
            <nb-tab *ngFor="let locale of locales; let i = index" tabTitle="{{ locale }}" tabId="{{ locale }}"
                    style="padding:20px 0 20px 0!important;">
              <ng-template nbTabTitle>
                <img src="/assets/images/flags/{{ locale }}.svg" width="24" *ngIf="locales.length > 1"/>
                <nb-icon *ngIf="!isFormValidForLocale(getTitleFormGroup(i))"
                         [icon]="isFormValidForLocale(getTitleFormGroup(i)) ? 'checkmark-circle-2-outline' : 'alert-triangle-outline'"
                         [ngClass]="{'red-color': !isFormValidForLocale(getTitleFormGroup(i)), 'green-color': isFormValidForLocale(getTitleFormGroup(i))}">
                </nb-icon>
              </ng-template>


              <nb-card [formGroup]="getTitleFormGroup(i)">
                <nb-card-body>
                  <nb-tabset>
                    <nb-tab style="padding:0!important;">
                      <ng-template nbTabTitle>
                        Main detail
                      </ng-template>


                      <div>
                        <div class="mb-3" style="margin-top: 20px;">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="{{ locale + '.title' }}"
                                   [formControl]="getTitleFormControl(locale, 'title')" placeholder="Title">
                            <label for="{{ locale + '.title' }}">Title*</label>
                          </div>
                        </div>

                        <div class="mb-3" *ngIf="!isArticle">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="{{ locale + '.nav_title' }}"
                                   [formControl]="getTitleFormControl(locale, 'nav_title')" placeholder="Nav title">
                            <label for="{{ locale + '.nav_title' }}">Navigation title*</label>
                          </div>
                        </div>


                        <div class="mb-3">
                          <label for="{{ locale + '.description' }}">Short description</label>
                          <textarea #contentTextarea class="form-control auto-resize" id="{{ locale + '.description' }}"
                                    [formControl]="getTitleFormControl(locale, 'description')"
                                    (input)="adjustTextareaHeight(contentTextarea)"
                                    placeholder="Description"></textarea>
                        </div>


                        <div class="mb-3" >
                          <app-editor-widget [locale]="locale" [options]="options"
                                             [formControl]="getTitleFormControl(locale, 'content')"></app-editor-widget>
                        </div>


                      </div>


                    </nb-tab>

                    <nb-tab style="padding:0!important;">
                      <ng-template nbTabTitle>
                        SEO optimization
                      </ng-template>

                      <div>
                        <div class="row" style="margin-top: 20px;">
                          <div class="col-12 col-md-6">

                            <div class="mb-3">
                              <div class="form-floating">
                                <input type="text" class="form-control" id="{{ locale + '.meta_title' }}"
                                       [formControl]="getTitleFormControl(locale, 'meta_title')"
                                       placeholder="Meta title">
                                <label for="{{ locale + '.meta_title' }}">Meta title*</label>
                              </div>
                            </div>

                            <div class="mb-3">
                              <div class="form-floating">
                                <textarea type="text" class="form-control  auto-resize" #contentTextarea2
                                          style="height:{{adjustTextareaHeight(contentTextarea2)}}"
                                          (input)="adjustTextareaHeight(contentTextarea2)"
                                          id="{{ locale + '.meta_description' }}"
                                          [formControl]="getTitleFormControl(locale, 'meta_description')"
                                          placeholder="Meta description"></textarea>
                                <label for="{{ locale + '.meta_description' }}">Meta description*</label>
                              </div>
                            </div>

                            <div class="mb-3">
                              <div class="form-floating">
                                <input type="text" class="form-control" id="{{ locale + '.meta_keywords' }}"
                                       [formControl]="getTitleFormControl(locale, 'meta_keywords')"
                                       placeholder="Keywords">
                                <label for="{{ locale + '.meta_keywords' }}">Keywords</label>
                              </div>
                            </div>

                          </div>
                          <div class="col-12 col-md-6">

                            <h4>Google Preview</h4>
                            <div class="google-preview">
                              <h3>{{ getTitleFormControl(locale, 'meta_title').value }}</h3>
                              <p>{{ getTitleFormControl(locale, 'meta_description').value }}</p>
                              <p>https://....{{ getTitleFormControl(locale, 'path').value }}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </nb-tab>

                  </nb-tabset>


                </nb-card-body>


              </nb-card>

            </nb-tab>
          </nb-tabset>




          <nb-card>
            <nb-card-header>
              <div>
                Body Widgets
                <button nbButton type="button" class="btn-ico-w pull-right" style="float:right" (click)="openWidgets()">
                  <nb-icon icon="plus-circle-outline"></nb-icon>
                  <span>Add widget</span>
                </button>
              </div>
            </nb-card-header>


            <nb-card-body>
              <div *ngFor="let widget of activeWidgets; let i = index" class="mb-3"
                   style="z-index:999999!important;border:solid 1px #dedede;">

                <ng-card style="z-index:9999999!important;">
                  <nb-card-body class="row">
                    <div class="col-12 col-md-2" style="cursor: pointer" (click)="openWidget(widget)">
                      <img style="width:100%" [src]="widget?.widget?.preview_image" alt="Preview Image">
                    </div>
                    <div class="col-12 col-md-8" style="cursor: pointer" (click)="openWidget(widget)">
                      <h4 *ngIf="widget?.title">{{widget?.title}}</h4>
                      <h4 *ngIf="!widget?.title">New widget</h4>
                    </div>
                    <div class="col-12 col-md-2 text-end">
                      <div>
                        <button nbButton size="tiny" class="btn-ico-w" type="button" (click)="up(widget)"
                                *ngIf="i !== 0">
                          <nb-icon icon="arrow-ios-upward-outline" style="color:#000!important;"></nb-icon>
                        </button>
                        <button nbButton size="tiny" class="btn-ico-w" type="button" (click)="down(widget)"
                                *ngIf="i !== activeWidgets.length - 1">
                          <nb-icon icon="arrow-ios-downward-outline" style="color:#000!important;"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </nb-card-body>
                </ng-card>
              </div>
            </nb-card-body>

          </nb-card>


          <nb-card>
            <nb-card-footer>

              <div class="row">
                <div class="col-6" *ngIf="userData?.isAdmin">
                  <button nbButton status="danger" class="btn-ico-w" type="button" (click)="cancel()" *ngIf="pageId">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                    <span>Delete</span>
                  </button>
                </div>

                <div class="col-6" *ngIf="!userData?.isAdmin">
                </div>


                <div class="col-12 col-md-6" style="text-align:right;">
                  <button nbButton status="primary" class="btn-ico-w" [disabled]="!pageFormGroup.valid">
                    <nb-icon icon="save-outline"></nb-icon>
                    <span>Save</span>
                  </button>
                </div>

              </div>


            </nb-card-footer>
          </nb-card>
        </div>


        <div class="col-12 col-md-4">


          <div class="row">
            <div class="col-12 col-md-12">
              <div [formGroup]="pageFormGroup">
                <nb-card>
                  <nb-card-header>
                    Parent
                  </nb-card-header>

                  <nb-card-body>
                    <!-- Sélection du parent -->
                    <div *ngIf="IS_NEWS_SYSTEM && userData?.isAdmin">
                      <select class="form-select mb-3" formControlName="parent_id" [(ngModel)]="selectedParentId">
                        <option value="">Option empty</option>
                        <option *ngFor="let option of dropdownOptions" [value]="option.id">{{ option.title }}</option>
                      </select>
                    </div>

                    <!-- Case à cocher "Is homepage" -->
                    <div class="mb-3" *ngIf="IS_NEWS_SYSTEM && userData?.isAdmin">
                      <label class="checkbox-inline">
                        <input type="checkbox" formControlName="isHomePage"> Is homepage
                      </label>
                    </div>

                    <!-- Champs cachés pour les non-admins -->
                    <input *ngIf="!(IS_NEWS_SYSTEM && userData?.isAdmin)" type="hidden" formControlName="parent_id">
                    <input *ngIf="!(IS_NEWS_SYSTEM && userData?.isAdmin)" type="hidden" formControlName="isHomePage">


                    <!-- Champ "Contain Articles" -->
                    <div class="mb-3"  *ngIf="IS_NEWS_SYSTEM && userData?.isAdmin">
                      <label class="checkbox-inline">

                        <input

                          type="checkbox"
                          formControlName="containArticles"
                        />
                        <span *ngIf="userData?.isAdmin">Contain Articles</span>
                      </label>
                    </div>

                    <input
                      *ngIf="IS_NEWS_SYSTEM && !userData?.isAdmin"
                      type="checkbox"
                      formControlName="containArticles"
                    />

                    <!-- Champ "Content type" -->
                    <div class="mb-3">
                      <label *ngIf="IS_NEWS_SYSTEM">Content type</label>
                      <nb-radio-group formControlName="isArticle" *ngIf="IS_NEWS_SYSTEM">
                        <nb-radio value="null">Null</nb-radio>
                        <nb-radio value="article">Article</nb-radio>
                        <nb-radio value="podcasts">Multiple podcasts</nb-radio>
                        <nb-radio value="podcast">Podcast</nb-radio>
                        <nb-radio value="photos">Photos</nb-radio>
                        <nb-radio value="program">Radio program</nb-radio>
                        <nb-radio value="win">Winner</nb-radio>
                      </nb-radio-group>

                      <!-- Champs cachés pour les utilisateurs non-admins -->
                      <input *ngIf="!(IS_NEWS_SYSTEM && userData?.isAdmin)" type="hidden"
                             formControlName="containArticles">
                      <div *ngIf="pageFormGroup?.get('isArticle')?.value == 'win'">
                        <mat-form-field appearance="fill">
                          <mat-label>Visible From</mat-label>
                          <input matInput [matDatepicker]="pickerFrom" formControlName="visibleFrom" name="visibleFrom"
                                 (dateChange)="onDateChange($event.value, 'visibleFrom')">
                          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                          <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>Visible To</mat-label>
                          <input matInput [matDatepicker]="pickerTo" formControlName="visibleTo" name="visibleTo"
                                 (dateChange)="onDateChange($event.value, 'visibleTo')">
                          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                          <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                      </div>


                    </div>


                  </nb-card-body>


                  <ng-container *ngIf="pageFormGroup?.get('isArticle')?.value">
                    <nb-card-footer>

                      <button
                        *ngIf="pageFormGroup?.get('isArticle')?.value == 'podcasts'"
                        nbButton
                        status="primary"
                        class="btn-ico-w"
                        type="button"
                        [disabled]="pageFormGroup.invalid"
                        (click)="openPodcasts()">
                        <nb-icon icon="plus-circle-outline"></nb-icon>
                        <span>Show podcasts list</span>
                      </button>

                      <button
                        *ngIf="pageFormGroup?.get('isArticle')?.value == 'podcast'"
                        nbButton
                        status="primary"
                        class="btn-ico-w"
                        type="button"
                        [disabled]="pageFormGroup.invalid"
                        (click)="openPodcast()">
                        <nb-icon icon="plus-circle-outline"></nb-icon>
                        <span>Show podcasts form</span>
                      </button>

                      <button
                        *ngIf="pageFormGroup?.get('isArticle')?.value == 'video'"
                        nbButton
                        status="primary"
                        class="btn-ico-w"
                        type="button"
                        [disabled]="pageFormGroup.invalid"
                        (click)="openWidgets()">
                        <nb-icon icon="plus-circle-outline"></nb-icon>
                        <span>Add videos list</span>
                      </button>

                    </nb-card-footer>

                  </ng-container>

                </nb-card>
              </div>

            </div>

          </div>



          <nb-card>
            <nb-card-header>
              <div>
                Video
              </div>
            </nb-card-header>

            <nb-card-body>
              <div class="mb-3" style="margin-top: 20px;">
                <div class="form-floating">
                  <input type="text" class="form-control" id="video_url"
                         formControlName="video_url" placeholder="Video URL">
                  <label for="video_url">Video url</label>
                </div>
              </div>
            </nb-card-body>

          </nb-card>


          <nb-card>
            <nb-card-header>
              Images
            </nb-card-header>

            <nb-card-body>
              <div class="row mb-3">
                <div class="col-12 col-md-{{ isArticle ? '12' : '6' }}">
                  <nb-card class="mb-3">
                    <nb-card-header>
                      Main image
                    </nb-card-header>
                    <nb-card-body class="p-0">
                      <div class="upload-container">
                        <input type="file" class="fileInput" id="fileInput" (change)="handleFileInput($event, 'image')">
                        <label for="fileInput" class="upload-label">
                          <img [src]="pageFormGroup?.get('image')?.value || image_url || '/assets/images/image.png'"
                               alt="Upload Image">
                        </label>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </div>
                <div class="col-12 col-md-6" *ngIf="!isArticle">
                  <nb-card>
                    <nb-card-header>
                      Large image
                    </nb-card-header>
                    <nb-card-body class="p-0">
                      <div class="upload-container">
                        <input type="file" class="fileInput" id="bigFileInput"
                               (change)="handleFileInput($event, 'big_image')">
                        <label for="bigFileInput" class="upload-label">
                          <img
                            [src]="pageFormGroup?.get('big_image')?.value || big_image_url || '/assets/images/image.png'"
                            alt="Upload Big Image">
                        </label>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </div>
              </div>


            </nb-card-body>
          </nb-card>


          <nb-accordion *ngIf="pageFormGroup?.get('isArticle')?.value == 'photos'" id="photosform">
            <nb-accordion-item [expanded]="true">
              <nb-accordion-item-header>
                <nb-icon icon="image-outline" style="color:#000!important;"></nb-icon>
                Photos
                <span>({{page?.photos?.length}})</span>
              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <app-images-upload
                  [pageId]="page?.id"
                  [photos]="photos"
                  (valueChange)="updatePhotosEventReciber($event)"

                ></app-images-upload>


              </nb-accordion-item-body>

            </nb-accordion-item>

          </nb-accordion>


        </div>

      </div>


    </nb-card-body>
  </nb-card>


</form>
