<nb-layout-header subheader class="action-nav">
  <nb-actions size="small">
    <nb-action>
      <h1 class="title-page" *ngIf="!page?.title">Pages</h1>
      <h1 class="title-page" *ngIf="page?.title">{{ page?.title + ' (' + totalItems + ')' || 'Site structure'}}</h1>
    </nb-action>
  </nb-actions>

  <nb-actions size="small" style="margin-left: auto">

    <nb-action class="action-right">
      <button nbButton status="primary" class="btn-ico-w" (click)="addPage(null)">
        <nb-icon icon="plus-outline"></nb-icon>
        <span *ngIf="!page?.containArticles">Create new page</span>
        <span *ngIf="page?.containArticles">Create new article</span>
      </button>
    </nb-action>
  </nb-actions>

</nb-layout-header>


<div class="p-20">

  <nz-breadcrumb class="breadcrumbs">
    <nz-breadcrumb-item>
      <a [routerLink]="['/admin/']">Dashboard</a>
    </nz-breadcrumb-item>


    <nz-breadcrumb-item>
      <a [routerLink]="['/admin/pages']">Pages</a>
    </nz-breadcrumb-item>

    <ng-container *ngFor="let breadcrumb of page?.breadcrumbs">
      <nz-breadcrumb-item>
        <ng-container *ngIf="breadcrumb.id !== page?.id">
          <a href="#" (click)="navigateToPage(breadcrumb.id)">{{ breadcrumb.title }}</a>
        </ng-container>
        <ng-container *ngIf="breadcrumb.id === page?.id">
          {{ breadcrumb.title }}
        </ng-container>
      </nz-breadcrumb-item>
    </ng-container>


  </nz-breadcrumb>


  <section class="table-container" *ngIf="!page?.containArticles && userData?.isAdmin">
    <table class="table">
      <thead>
      <tr>
        <th>{{ page?.title || 'Main'}}</th>
        <th class="text-end">Action</th>
      </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of pages; let i = index">
        <tr cdkDrag class="hovertr" >
          <td>
            <a class="text-dark" style="text-decoration: none; display: flex; align-items: center; justify-content: space-between;" (click)="navigateToPage(item.id)">
              <div style="display: flex; align-items: center; flex: 1;">

                <nb-icon [style.color]="item.children_count ? '#d3962d' : '#666'" style="margin-right: 5px;" [icon]="item.children_count ? 'folder-outline' : 'file-text-outline'"></nb-icon>

                <div style="flex: 1;">
                  <h3 style="font-size:0.9rem; margin:0 0 0 5px; padding:0; line-height:1rem;font-weight:600;"><nb-icon style="margin-right: 5px;"  *ngIf="item.isHomePage" icon="home-outline"></nb-icon> {{ item.title }} ({{ item.id }})</h3>
                  <div style="white-space: pre-wrap; margin-top:5px;" *ngIf="item.description">
                    {{ item.description }}
                  </div>
                </div>
              </div>

              <nb-icon *ngIf="item.children_count" [icon]="isExpanded(item) ? 'arrow-ios-downward-outline' : 'arrow-ios-forward-outline'"></nb-icon>
            </a>

          </td>
          <td class="text-end">
            <button nbButton size="small" (click)="addPage(item.id)" style="margin-right:5px;">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </section>

  <section class="table-container" *ngIf="!page?.containArticles && userData?.isManager">
    <table class="table">
      <thead>
      <tr>
        <th>{{ page?.title || 'Main'}}</th>
        <th class="text-end">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of pages; let i = index">
        <tr class="hovertr"  >
          <td>
            <a class="text-dark" style="text-decoration: none; display: flex; align-items: center; justify-content: space-between;" (click)="navigateToPage(item.id)">
              <div style="display: flex; align-items: center; flex: 1;">

                <nb-icon [style.color]="item.children_count ? '#d3962d' : '#666'" style="margin-right: 5px;" [icon]="item.children_count ? 'folder-outline' : 'file-text-outline'"></nb-icon>

                <div style="flex: 1;">
                  <h3 style="font-size:0.9rem; margin:0 0 0 5px; padding:0; line-height:1rem;font-weight:600;"><nb-icon style="margin-right: 5px;"  *ngIf="item.isHomePage" icon="home-outline"></nb-icon> {{ item.title }} ({{ item.id }})</h3>
                  <div style="white-space: pre-wrap; margin-top:5px;" *ngIf="item.description">
                    {{ item.description }}
                  </div>
                </div>
              </div>

              <nb-icon *ngIf="item.children_count" [icon]="isExpanded(item) ? 'arrow-ios-downward-outline' : 'arrow-ios-forward-outline'"></nb-icon>
            </a>

          </td>
          <td class="text-end">
            <button nbButton size="small" (click)="addPage(item.id)" style="margin-right:5px;">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </section>


  <section class="table-container" *ngIf="page?.containArticles">
    <table class="table">
      <thead>
      <tr>
        <th>{{ page?.title || 'Main' }}</th>
        <th class="text-end">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of pages">
        <tr>
          <td>
            <a class="text-dark" style="text-decoration: none; display: flex; align-items: center; justify-content: space-between;" (click)="navigateToPage(item.id)">
              <div style="display: flex; align-items: center; flex: 1;">
                <nb-icon [style.color]="item.children_count ? '#d3962d' : '#666'" style="margin-right: 5px;" [icon]="item.children_count ? 'folder-outline' : 'file-text-outline'"></nb-icon>
                <div style="flex: 1;">
                  <h3 style="font-size: 0.9rem; margin: 0 0 0 5px; padding: 0; line-height: 1rem; font-weight: 600;">
                    <nb-icon style="margin-right: 5px;" *ngIf="item.isHomePage" icon="home-outline"></nb-icon>
                    {{ item.title }} ({{ item.id }})
                  </h3>
                  <div style="white-space: pre-wrap; margin-top: 5px;" *ngIf="item.description">
                    {{ item.description }}
                  </div>
                </div>
              </div>
              <nb-icon *ngIf="item.children_count" [icon]="isExpanded(item) ? 'arrow-ios-downward-outline' : 'arrow-ios-forward-outline'"></nb-icon>
            </a>
          </td>
          <td class="text-end">
            <button nbButton size="small" (click)="addPage(item.id)" style="margin-right: 5px;">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <div class="pagination"  *ngIf="page?.containArticles">
      <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage === 1">
          <button class="page-link" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        </li>
        <li class="page-item" *ngFor="let page of getPager()">
          <button class="page-link" (click)="onPageChange(page)" [class.active]="currentPage === page">{{ page }}</button>
        </li>
        <li class="page-item" [class.disabled]="currentPage === totalPages">
          <button class="page-link" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">Next</button>
        </li>
      </ul>
    </div>


  </section>



</div>
