<nb-layout>


  <nb-layout-header fixed>


    <nb-actions size="small">
      <nb-action>
        <button nbButton (click)="toggleMainMenu()" size="small">
          <nb-icon icon="menu-outline"></nb-icon>
        </button>
      </nb-action>


      <nb-action>
        <h4 class="site-name"><strong>Workspace</strong> <span *ngIf="!isMobile"> {{selectedDomain?.name}}</span></h4></nb-action>
    </nb-actions>


    <!-- Spacer pour pousser les actions à droite -->
    <nb-layout-column style="flex: 1 1 auto;"></nb-layout-column>


    <nb-actions size="small">

      <nb-action size="small" *ngIf="!isMobile">
        <nb-search type="rotate-layout"></nb-search>
      </nb-action>


      <nb-action  size="small" *ngIf="!isMobile">
        <nb-user size="medium"
                 name="{{userData?.name}}"
                 title="{{userData?.name}}"
                 showName=false
                 [nbContextMenu]="itemsUserMenu"
                 nbContextMenuTag="my-context-menu" >
        </nb-user>
      </nb-action>
    </nb-actions>
  </nb-layout-header>


  <nb-sidebar  class="sidebarLeft"  tag="min" [state]="compactedMenuLeft" *ngIf="!isMobile && userData?.isAdmin">
    <nb-menu [items]="itemsLeft" [expanded]="true"></nb-menu>
  </nb-sidebar>

  <nb-sidebar  class="sidebar" tag="sright"  responsive [state]="compactedMenu" >
    <nb-menu  [items]="items" [expanded]="true" ></nb-menu>
  </nb-sidebar>

  <nb-layout-column class="p-0">


    <app-spinner *ngIf="isLoading$ | async"></app-spinner>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
