import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, Optional} from '@angular/core';

import {
  NbMenuItem,
  NbMenuService,
  NbSearchService,
  NbSidebarService,
  NbSidebarState,
  NbToastrService
} from "@nebular/theme";
import {GetConfigurationService} from "../services/getConfiguration/get-configuration.service";
import {HttpInterceptorService} from "../interceptors/http-interceptor.service";
import {HttpLoaderService} from "../interceptors/http-loader.service";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {GetCurrentPropertyImportService} from "../services/redis/getCurrentPropertyImport.service";
import {ToastService} from "../services/toast/toast.service";
import { interval, Subject } from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import { Directive, HostBinding } from '@angular/core';
import {ContactsComponent} from "../modules/contacts/pages/contacts/contacts.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Observable} from "rxjs";
import {Share} from "../class/share";
import {DeviceDetectorService} from "ngx-device-detector";
import { environment} from "../../environments/environment";



export interface MenuItem {
  title: string;
  icon?: string;
  open?: boolean;
  children?: MenuGroup[];
}

export interface MenuGroup {
  title: string;
  options: MenuOption[];
}

export interface MenuOption {
  label: string;
  selected?: boolean;
}


@Component({
  selector: 'app-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends Share implements OnInit, OnDestroy, AfterViewInit {
  environment = environment;
  contactsCategories: any[] = [];

  isCollapsed: boolean = false;
  resizing: boolean = false;
  startX: number = 0;
  startWidth: number = 0;


  isSidebarOpen = true;

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  items: NbMenuItem[] = [];


  itemsLeft: NbMenuItem[] = [
    {
      title: "Settings",
      link: '/admin/settings',
      icon: 'settings-outline',
      expanded: false,
      pathMatch: 'prefix',
    }
  ];


  itemsUserMenu: NbMenuItem[] = [
    {
      title: 'Logout',
      icon: 'unlock-outline',
      link: '/logout'
    },
  ];
  isLoading$ = this.httpLoaderService.isLoading$;
  searchValue = '';

  selectedDomain!: any;
  constructor(
    private configurationService: GetConfigurationService,
    private httpLoaderService: HttpLoaderService,
    private authService: AuthService,
    private router: Router,
    private getCurrentPropertyImportService: GetCurrentPropertyImportService,
    private toastrService: NbToastrService,
    private toastService: ToastService,
    private searchService: NbSearchService,
    @Optional() public _NzDrawerService: NzDrawerService,
    deviceService: DeviceDetectorService,
    menu: NbMenuService,
    public sidebarService: NbSidebarService
  ) {
    super('Child', deviceService);

    // Obtenez le domaine sélectionné à partir du service d'authentification
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.selectedDomain = selectedDomain;
    } else {
      console.error('Selected domain is not set.');
    }

    menu.onItemClick().subscribe(() => {
      if(this.isMobile) {
        sidebarService.collapse('sright');
      }
    });

    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        this.searchValue = data.term;

        let modalRef = this._NzDrawerService.create({
          nzContent: ContactsComponent,
          nzWidth: '80vw',
          nzWrapClassName: 'nopadding',
          nzTitle: "All contacts",

          nzContentParams: {
            searchValue: this.searchValue
          }
        });

        const storedMenuState = localStorage.getItem('sidebarState');
        this.compactedMenu = storedMenuState ? (storedMenuState as NbSidebarState) : 'collapsed';
      })
  }


  userMenuVisible = false;
  toggleUserMenu() {
    this.userMenuVisible = !this.userMenuVisible;
  }


  compactedMenu: NbSidebarState = 'collapsed';
  compactedMenuLeft: NbSidebarState = 'compacted';
  toggleMainMenu() {
    this.compactedMenu = this.compactedMenu === 'expanded' ? 'collapsed' : 'expanded';
    localStorage.setItem('sidebarState', this.compactedMenu);
  }

  userData: any;

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    const storedMenuState = localStorage.getItem('sidebarState');
    this.compactedMenu = storedMenuState ? (storedMenuState as NbSidebarState) : 'expanded';

    this.loadConfiguration();
  }

  datas: any;
  IS_REAL_ESTATE: any;
  ENABLE_AFTER_BEFORE: any;

  private loadConfiguration() {
    this.configurationService.getConfiguration().subscribe(datas => {
      this.contactsCategories = datas?.data?.addressesCategories;

      this.IS_REAL_ESTATE = datas?.data?.IS_REAL_ESTATE;
      this.ENABLE_AFTER_BEFORE = datas?.data?.ENABLE_AFTER_BEFORE;

      if (this.contactsCategories && this.contactsCategories.length > 0) {
        const contactsSubMenu: NbMenuItem[] = this.contactsCategories.map(category => {
          return {
            title: category.name,
            link: `/admin/contacts/${category.key}`,
            pathMatch: 'prefix',
          };
        });

        contactsSubMenu.push({
          title: 'All',
          link: '/admin/contacts',
          pathMatch: 'prefix',
        });

        const contactsMenuItem = this.items.find(item => item.title === "Contacts");

        if (contactsMenuItem) {
          contactsMenuItem.children = contactsSubMenu;
        }
      }
      this.userData = this.authService.getUserData();

      if(this.userData) {

        if (!this.IS_REAL_ESTATE) {


          if (this.userData?.isAdmin) {
            this.items = [

              {
                title: "Dashboard",
                icon: 'home-outline',
                link: '/admin'
              },

              {
                title: "Calendar",
                icon: 'calendar-outline',
                link: '/admin/calendar'
              },

              {
                title: "Pages",
                link: '/admin/pages',
                icon: 'book-outline',
                expanded: false,
                pathMatch: 'prefix',
              },


              {
                title: "Sliders",
                link: '/admin/sliders',
                icon: 'monitor-outline',
                expanded: false,
                pathMatch: 'prefix',
              },

              {
                title: "Advertisements",
                link: '/admin/advertisements',
                icon: 'monitor-outline',
                expanded: false,
                pathMatch: 'prefix',
              },

              {
                title: "Contact requests",
                link: '/admin/contact-requests',
                icon: 'at-outline',
                expanded: false,
                pathMatch: 'prefix',
              }
            ];
          } else {
            this.items = [

              {
                title: "Dashboard",
                icon: 'home-outline',
                link: '/admin'
              },

              {
                title: "Pages",
                link: '/admin/pages',
                icon: 'book-outline',
                expanded: false,
                pathMatch: 'prefix',
              },


            ];

          }
        } else {
          this.items = [

            {
              title: "Dashboard",
              icon: 'home-outline',
              link: '/admin'
            },


            {
              title: "Calendar",
              icon: 'calendar-outline',
              link: '/admin/calendar'
            },

            {
              title: "Properties",
              icon: 'home-outline',
              link: '/admin/properties',
              pathMatch: 'prefix',
            },

            {
              title: "Client search",
              icon: 'search-outline',
              link: '/admin/clients-search'
            },

            {
              title: "Contacts",
              link: '/admin/contacts',
              icon: 'people-outline',
              expanded: false,
              pathMatch: 'prefix',
            },

            {
              title: "Registrations",
              link: '/admin/registration',
              icon: 'repeat-outline',
              expanded: false,
              pathMatch: 'prefix',
            },

            {
              title: "Pages",
              link: '/admin/pages',
              icon: 'book-outline',
              expanded: false,
              pathMatch: 'prefix',
            },


            {
              title: "Sliders",
              link: '/admin/sliders',
              icon: 'monitor-outline',
              expanded: false,
              pathMatch: 'prefix',
            },

            {
              title: "Contact requests",
              link: '/admin/contact-requests',
              icon: 'at-outline',
              expanded: false,
              pathMatch: 'prefix',
            }
          ];
        }

      }

    });
  }



  private destroy$ = new Subject<void>(); // Un sujet pour gérer la destruction du composant
  public currentPropertyImport: any;
  public currentPropertyImportId: any;


  private checkCurrentPropertyImport() {
    this.getCurrentPropertyImportService.getCurrentPropertyImport().subscribe(response => {
      if (response?.data[0]?.id !== this.currentPropertyImportId) {
        this.currentPropertyImport = response?.data;
        this.currentPropertyImportId = this.currentPropertyImport[0]?.id;
        this.toastService.showToastBottomLeft('Property ' + this.currentPropertyImport[0]?.ref + ' imported successfully from ' + this.currentPropertyImport[1]?.full_name, 'Success!', 'success');
      }
    });
  }

  logout(): void {
    this.authService.handleLogout().subscribe(
      (response) => {
        console.log('Logout successful:', response);
        // Redirigez ou effectuez toute autre action après la déconnexion
      },
      (error) => {
        console.error('Logout error:', error);
        // Gérez les erreurs de déconnexion
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(); // Émettez une notification de destruction
    this.destroy$.complete(); // Complétez le sujet pour éviter toute fuite de mémoire
  }

}
