import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "../../../services/auth/auth.service";
import {TreeNode} from "../interfaces/pagesDatas";

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private baseUrl!: string;
  private apiUrl!: string;
  private apiUrlWithoutPage!: string;
  private publicApiUrl!: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService // Inject the authentication service
  ) {
    // Get the selected domain from the authentication service
    const selectedDomain = this.authService.getSelectedDomain();
    if (selectedDomain && selectedDomain.domainName) {
      this.apiUrl = `${selectedDomain.domainName}/api/pages`;
      this.apiUrlWithoutPage = `${selectedDomain.domainName}/api`;
      this.baseUrl = `${selectedDomain.domainName}/pages`;
    } else {
      console.error('Selected domain is not set.');
    }

  }

  listPages(parent:any): Observable<any> {
    if(parent ==null || parent =='null') {
      parent = '';
    } else {
      parent = '/' + parent;
    }
    return this.http.get<any>(this.apiUrl + parent);
  }

  pagination(parent: any, page: number = 1, perPage: number = 15): Observable<any> {
    let url = this.apiUrl;

    if (parent != null && parent !== 'null') {
      url += '/' + parent;
    }

    return this.http.get<any>(url, {
      params: {
        page: page.toString(),
        per_page: perPage.toString()
      }
    });
  }



  getPage(id:any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/get/' + id);
  }


  // Method to update page positions
  updateNodes(nodes:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-ranks`, {'data' : nodes});
  }


  createOrUpdatePage(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/createOrUpdatePage`, pageData);
  }

  uploadImage(imageData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/upload-image`, imageData);
  }

  getuploadUrl() {
    return `${this.baseUrl}/upload-image`;
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/delete/` + id);
  }


  // Method to update page positions
  translate(pageData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/translate`, pageData);
  }


  uploadImages(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.apiUrlWithoutPage}/photos/upload`, formData);
  }



  updateRank(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.apiUrlWithoutPage}/photos/update-ranks`, formData);
  }

  removeImages(formData: FormData): Observable<any> {
    // Upload images using the provided FormData
    return this.http.post(`${this.apiUrlWithoutPage}/photos/delete`, formData);
  }

  deletePhoto(photoId: any, propertyId: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrlWithoutPage}/photos/delete`, { photoId, propertyId, 'reference_table' : 'pages' });
  }


}
